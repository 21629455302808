import React, { useState, useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

//Components
import Layout from '../components/layout/layout';
import SEO from '../components/seo/seo';
import ContentGrid from '../components/content-grid/content-grid.component';
import Main from '../components/main/main.component';
import GaLink from '../components/ga-link/ga-link.component';
// import Aside from '../components/aside/aside.component';
// import SideBar from '../components/sidebar/sidebar.component';

//utilities
import { getWalrusQuote, asciiWalrus } from '../utilities/walrusRelated';

//Types
import { AllMDRemarkTypes } from '../types/allMarkdownRemark.types';

//Styles & Assets
const WalrusIcon = require('../../static/walrus-3d-no-bg.svg');
// const WalrusIcon = require('../../static/walrus-3d-roumd-outline.svg');
import * as stls from './index.styles.module.scss';

type IndexPostProps = AllMDRemarkTypes;

const IndexPage: React.FC<PageProps<IndexPostProps>> = ({ data }) => {
  const [indexState, setState] = useState({
    counter: 0,
    quote: getWalrusQuote(),
    fadeIn: `${stls.fadeIn}`,
    secretCounter: 0,
    counterContainer: `${stls.hidden}`,
  });

  const counterTrigger = () => {
    indexState.counter === 2
      ? setState({
          ...indexState,
          counter: 0,
          quote: getWalrusQuote(),
          fadeIn: `${stls.fadeIn}`,
          secretCounter: ++indexState.secretCounter,
          counterContainer: `${stls.secretCounterContainer} ${stls.fadeIn}`,
        })
      : setState({
          ...indexState,
          counter: ++indexState.counter,
          fadeIn: '',
        });
  };

  const { fadeIn, quote, secretCounter, counterContainer } = indexState;

  useEffect(() => {
    console.log(asciiWalrus);
  }, []);

  return (
    <Layout>
      <SEO title='The Coding Walrus' />
      <div className={`${stls.banner} ${stls.fadeInFast}`}>
        <p className={`${stls.quote} ${fadeIn}`}>{quote}</p>
        <img
          onClick={() => {
            counterTrigger();
            trackCustomEvent({
              category: 'Banner Walrus',
              action: 'click',
              label: 'Clicking of the Walrus',
            });
          }}
          className={`${stls.logo} ${stls.fadeIn}`}
          src={WalrusIcon}
          alt='Walrus Logo'
        />
        <div className={counterContainer}>{secretCounter}</div>
      </div>
      <ContentGrid>
        <Main>
          <div className={stls.indexPage}>
            <h1>Latest Pickings</h1>
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <div key={node.id} className={stls.blogPost}>
                <GaLink
                  className={stls.link}
                  to={node.fields.slug}
                  category={`Index Link`}
                >
                  <h2>
                    {node.frontmatter.title} - {node.frontmatter.date}
                  </h2>
                </GaLink>
                <p>
                  <strong>Lure: </strong>
                  {node.frontmatter.description}
                </p>
                <p>
                  <strong>Smol Slice: </strong>
                  {node.excerpt}
                </p>
              </div>
            ))}
            <h2>Total pickings to date: {data.allMarkdownRemark.totalCount}</h2>
          </div>
        </Main>
      </ContentGrid>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 20
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          excerpt
          frontmatter {
            date
            title
            description
          }
          fields {
            slug
          }
        }
      }
    }
    file(id: { eq: "61dbd160-b834-5536-916d-fd7fa177b4dc" }) {
      relativePath
    }
  }
`;
