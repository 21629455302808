import React from 'react';
import { Link } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

interface GaLinkProps {
  to: string;
  className?: string;
  category?: string;
}

const GaLink: React.FC<GaLinkProps & React.ReactNode> = ({
  to,
  className,
  children,
  category,
}) => {
  category = category ? category : `Internal Link`;

  const trackLocalLink = () =>
    trackCustomEvent({
      category,
      action: 'click',
      label: to,
    });

  return (
    <Link
      to={to}
      className={className ? `${className}` : null}
      onClick={trackLocalLink}
    >
      {children}
    </Link>
  );
};

export default GaLink;
